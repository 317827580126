import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	private readonly key: string = 'PROFILE_NAME';
	profileName: BehaviorSubject<string> = new BehaviorSubject(undefined);

	constructor(private localStorage: LocalStorageService) {}

	loadProfileName = () => {
		this.profileName.next(this.localStorage.getData(this.key));
	};

	setProfileName = (name: string) => {
		this.localStorage.setItem(this.key, name);
		this.profileName.next(name);
	};
}
